<template lang="pug">
.pa-0.ma-0
  div(class="d-flex justify-end")
    v-select(
      v-model="dataset.versao_minima",
      :items="versoesForSQL",
      :disabled="disabled",
      @change="changeVersion",
      hide-details,
      outlined,
      dense,
      label="Versão",
      placeholder="Selecione a versão",
      style="width: 280px"
    )

    v-spacer
    v-btn.mr-2(
      @click="$emit('command', 'delete', index)",
      :disabled="disabled || progress.deleteSQL",
      outlined,
      color="error"
    )
      v-icon(left) mdi-close
      span {{ progress.deleteSQL ? "Excluindo..." : "Excluir" }}

    v-btn(
      @click="$emit('command', 'save', index)",
      :disabled="disabled || progress.saveSQL",
      outlined,
      color="success"
    )
      v-icon(left) mdi-check
      span {{ progress.saveSQL ? "Salvando..." : "Salvar" }}

  v-textarea.textAreaSQL.mt-2(
    background-color="#f0f0f0",
    v-model="dataset.sql",
    label="SQL",
    dense,
    :height="height",
    :disabled="disabled",
    outlined,
    :rules="[rules.required, rules.onlySelectSQL]"
  )
</template>

<script>
export default {
  name: "SQLEditor",
  props: {
    index: {
      type: Number,
      required: false,
    },
    versoes: {
      type: Array,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    dataset: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Object,
      default: false,
      default: () => ({
        newSQL: false,
        saveSQL: false,
        deleteSQL: false,
      }),
    },
  },

  data() {
    return {
      versoesForSQL: [],
      rules: {
        required: (value) => !!value || "Campo obrigatório",
        onlySelectSQL: (value) => {
          const sql = value.replace(/(--.*)|(\s+)/g, "").toLowerCase();

          if (/(delete|drop|truncate|alter|update|insert|create)/i.test(sql)) {
            return "Apenas consultas SELECT são permitidas";
          }
          return true;
        },
      },
    };
  },

  methods: {
    formatForSQL() {
      this.versoesForSQL = this.versoes.map((item) => item.versao.toString());
    },

    changeVersion(value) {
      const versionSelected = this.versoes.find(
        (item) => item.versao === value
      );
      if (versionSelected) {
        this.dataset.versao_minima = versionSelected.versao;
      }
    },
  },

  mounted() {
    this.formatForSQL();
  },
};
</script>

<style>
.textAreaSQL {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: monospace;
}
</style>