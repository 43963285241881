export default {
  showLoader(state, text) {
    state.text = text;
    state.show = true;
  },
  hideLoader(state) {
    state.text = null;
    state.show = false;
  },
};
