<template>
</template>

<script>
export default {
    props: ['on', 'actions'],

    render: () => null,

    created() {
        const { on } = this;

        on('beforeRender', ({ node }) => {
            if (node.depth !== 0) {
                node.retract(); // Retrair outros nós
            }
        });
    }
};
</script>