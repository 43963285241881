<template lang="pug">
v-card(:class="classes")
  v-divider.ma-1
  v-layout.pl-5.pr-5(row, no-gutters, wrap, text-center, align-center)
    v-menu(
      offset-y,
      v-for="(item, index) in filterProps.items",
      :key="item.text",
      :close-on-content-click="false",
      transition="slide-y-transition"
    )
      template(v-slot:activator="{ on, attrs }")
        v-chip.ml-1(
          v-bind="attrs",
          v-on="on",
          :color="getChipColor(item.text)",
          @click="selectChip(item.text)"
        ) {{ item.text }}

      v-card
        .row.no-gutters.wrap.text-center.align-center
          v-autocomplete.py-2(
            v-if="item.type === 'select'",
            :items="item.values",
            item-text="text",
            item.value="value",
            label="Selecione",
            hide-details,
            rounded,
            return-object,
            :single-line="!item.multiple",
            :multiple="item.multiple",
            @change="addFilter",
            clearable
          )
          .row.no-gutters.wrap.text-center.align-center.px-2(
            v-if="item.type === 'date' || item.type === 'datetime'"
          )
            v-text-field.mr-2(
              :type="item.type == 'date' ? 'date' : 'datetime-local'",
              hide-details,
              dense,
              v-model="item.value",
              :label="item.text",
              :max="item.date_max",
              :min="item.date_min",
              @change="addFilter(item)"
            )
            v-btn(small, icon, color="success", @click="addFilter(item)")
              v-icon(small) fa-check
            v-btn(small, icon, color="error", @click="addFilter()")
              v-icon(small) fa-close

    v-spacer

    v-text-field.md-3.mr-1(
      v-if="filterProps.includeSearch",
      v-model="filterProps.searchText",
      clearable,
      color="primary",
      dense,
      outlined,
      prepend-inner-icon="mdi-magnify",
      label="Buscar",
      hide-details
    )
    slot(name="actions")

  v-divider.ma-1
  v-data-iterator(
    :items="getItems",
    :search="filterProps.searchText",
    :disable-pagination="paginate == false",
    :items-per-page.sync="itemsPerPage",
    :page.sync="page",
    :no-data-text="'Nenhum registro encontrado'",
    :no-results-text="'Nenhum registro encontrado'",
    hide-default-footer
  )
    template(v-slot:no-results)
      v-card.elevation-0.pa-15
        v-row(align="center", justify="center")
          div Nenhum registro encontrado

    template(v-slot:default="{ items }")
      slot(:items="items", :page="page")

    template(v-slot:footer)
      v-divider
      v-row.align-center.justify-end(v-if="paginate", no-gutters)
        slot(name="footer")
        v-spacer
        h5.pt-1 Itens por página:
        v-select.mx-5.pt-6(
          dense,
          outlined,
          style="max-width: 120px",
          v-model="itemsPerPage",
          :items="paginationOptions"
        )
        h5.pt-1.mr-5 {{ getDescItemsPaginados() }}

        v-pagination(
          circle,
          v-model="page",
          :length="itemsCountPages()",
          :total-visible="7"
        )
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    filterProps: {
      type: Object,
      required: false,
      default: () => ({
        items: [],
        includeSearch: true,
        searchText: "",
        useSearchHash: false,
      }),
    },
    paginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    paginationOptions: {
      type: Array,
      required: false,
      default: () => [15, 30, 100, { text: "Todos", value: -1 }],
    },
    classes: {
      type: String,
      required: false,
      default: "elevation-2",
    },
  },

  data: () => ({
    filteredItems: [],
    filters: [],
    selectedChip: null,
    search: "",
    page: 1,
    itemsPerPage: 15,
  }),

  methods: {
    emitEvent(event) {
      this.$emit(event);
    },

    getChipColor(field) {
      const filter = this.filters.filter((item) => item.chip === field);

      return filter.length > 0 ? "primary" : "";
    },

    selectChip(field) {
      this.selectedChip = field;
    },

    addFilter(filter) {
      this.deleteFilter(this.selectedChip);

      if (filter) {
        if (Array.isArray(filter)) {
          if (filter.length > 0) {
            this.filters.push({ chip: this.selectedChip, filter });
          }
        } else {
          this.filters.push({ chip: this.selectedChip, filter });
        }
      }

      this.applyFilters();
    },

    deleteFilter(field) {
      this.filters.map((item, index) => {
        if (item.chip == field) {
          this.filters.splice(index, 1);
        }
      });
    },

    applyFilters() {
      if (this.filters && this.filters.length == 0) {
        this.filteredItems = this.items;
        this.itemsCountPages();
        return;
      }

      this.filteredItems = this.items.filter((item) => {
        let result = false;

        for (const { filter } of this.filters) {
          if (Array.isArray(filter)) {
            result = filter.some((f) => {
              if (f.fn) {
                return f.fn([item, f]);
              }

              return item[f.field] === f.value;
            });
          } else if (filter.fn) {
            result = filter.fn([item, filter]);
          } else {
            result = item[filter.field] === filter.value;
          }

          if (!result) {
            break;
          }
        }

        return result;
      });
    },

    itemsCountPages() {
      const items =
        this.filteredItems.length > 0 ? this.filteredItems : this.items;

      let pages = Math.round(items.length / this.itemsPerPage);
      if (!pages) {
        pages = 0;
      }

      if (pages * this.itemsPerPage < this.filteredItems.length) {
        pages++;
      }

      return pages;
    },

    getDescItemsPaginados() {
      const finalItems = this.page * this.itemsPerPage;
      const inicialItems = finalItems - this.itemsPerPage + 1;
      const totalItems = this.filteredItems.length;

      return `${totalItems > 0 ? inicialItems : 0} - ${
        totalItems < finalItems ? totalItems : finalItems
      } / ${totalItems}`;
    },
  },

  watch: {
    "filterProps.searchText"() {
      if (this.filterProps.useSearchHash) {
        window.location.hash = this.filterProps.searchText ?? "";
      }
    },
  },

  computed: {
    getItems() {
      const items = this.filters.length > 0 ? this.filteredItems : this.items;
      this.filteredItems = items;
      return items;
    },
  },
};
</script>
