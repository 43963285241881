<template lang="pug">
div
	vue-pivottable-ui(
		v-if="created && manipulate",
		v-model="configuracao",
		:data="dataset",
		:rows="rows",
		:cols="cols",
		:vals="vals",
		:rowOrder="rowOrder"
		:rowTotal="!disableRowTotal"
		:colOrder="colOrder"
		:colTotal="!disableColTotal"
		:value-filter="valueFilter",
		:rendererName="rendererName",
		:aggregator-name="aggregatorName",
		locale="br"
		:renderers="renderers",
		:locales="locales",
		:aggregators="aggregators"
		:colLimit="500000"
		:rowLimit="500000"
		:menuLimit="500000"
	)
	
		template(v-slot:rendererCell="props")
			div.fas.fa-table(style="margin-right: 0.25rem") {{ props.rendererName }}

		template(v-slot:aggregatorCell="props")
			div.fas.fa-calculator(style="margin-right: 0.25rem") {{ props.aggregatorName }}

		template(v-slot:colGroup)
			colGroup(width="300", height="100")
			colGroup

	vue-pivottable(
		v-if="created && !manipulate"
		:data="dataset"
		:rows="rows"
		:cols="cols"
		:vals="vals"
		:rowOrder="rowOrder"
		:rowTotal="!disableRowTotal"
		:colOrder="colOrder"
		:colTotal="!disableColTotal"
		:value-filter="valueFilter"
		:rendererName="rendererName"
		:aggregatorName="aggregatorName"
		locale="br"
		:renderers="renderers"
		:locales="locales"
		:aggregators="aggregators"
		:colLimit="500000"
		:rowLimit="500000"
		:menuLimit="500000"
	)
	
</template>

<script>
import PlotlyRenderer from "@vue-pivottable/plotly-renderer";
import ScrollRenderer from "@vue-pivottable/scroll-renderer";
import { VuePivottableUi, VuePivottable, PivotUtilities } from "vue-pivottable";

export default {
	name: "pivot-table",
	components: {
		VuePivottableUi,
		VuePivottable,
	},
	props: {
		dataset: {
			type: Array,
			required: false,
			default: () => [
				{produto: "A", categoria: "X", quantidade: 10},
				{produto: "B", categoria: "Y", quantidade: 20},
			]
		},
		rows: {
			type: Array,
			required: false,
			default: () => []
		},
		cols: {
			type: Array,
			required: false,
			default: () => []
		},
		vals: {
			type: Array,
			required: false,
			default: () => []
		},
		rowOrder: {
			type: String,
			required: false,
			default: "key_a_to_z"
		},
		colOrder: {
			type: String,
			required: false,
			default: "key_a_to_z"
		},
		valueFilter: {
			type: Object,
			required: false,
			default: () => ({})
		},
		rendererName: {
			type: String,
			required: false,
			default: "Tabela"
		},
		aggregatorName: {
			type: String,
			required: false,
			default: "Contar"
		},
		manipulate: {
			type: Boolean,
			required: false,
			default: true
		},
		disableColTotal: {
			type: Boolean,
			required: false,
			default: false
		},
		disableRowTotal: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			created: true,
			configuracao: {
				rendererName: "Tabela",
				aggregatorName: "Contar",
				rowOrder: "key_a_to_z",
				colOrder: "key_a_to_z",
				valueFilter: {},
				rows: [],
				cols: [],
				vals: [],
				aggregators: {},
			},
			renderers: {},
			aggregators: {},
			locales: {}
		}
	},
	watch: {
		vals() {
			this.created = false;
			this.$nextTick(() => {
				this.created = true;
			});
		},
		aggregatorName() {
			this.created = false;
			this.$nextTick(() => {
				this.created = true;
			});
		},
		configuracao() {
			let localCenario = JSON.parse(JSON.stringify(this.configuracao));
  
			delete localCenario.hiddenAttributes
			delete localCenario.hiddenFromAggregators
			delete localCenario.hiddenFromDragDrop
			delete localCenario.sortonlyFromDragDrop
			delete localCenario.disabledFromDragDrop
			delete localCenario.menuLimit
			delete localCenario.attributes
			delete localCenario.data
			delete localCenario.unusedAttrs

			this.$emit("input", localCenario);
		}
	},
	methods: {
		renderersCreate() {
			this.renderers = (() => ({
				"Gráfico de colunas agrupadas": PlotlyRenderer["Grouped Column Chart"],
				"Gráfico de colunas empilhadas": PlotlyRenderer["Stacked Column Chart"],
				"Gráfico de barras agrupadas": PlotlyRenderer["Grouped Bar Chart"],
				"Gráfico de barras empilhadas": PlotlyRenderer["Stacked Bar Chart"],
				"Gráfico de linha": PlotlyRenderer["Line Chart"],
				"Gráfico de pontos": PlotlyRenderer["Dot Chart"],
				"Gráfico de área": PlotlyRenderer["Area Chart"],
				"Gráfico de dispersão": PlotlyRenderer["Scatter Chart"],
				"Gráfico de pizza múltiplo": PlotlyRenderer["Multiple Pie Chart"],
				"Tabela": ScrollRenderer.Table,
				"Tabela de Mapa de Calor": ScrollRenderer["Table Heatmap"],
				"Tabela de Mapa de Calor(Colunas)": ScrollRenderer["Table Col Heatmap"],
				"Tabela de Mapa de Calor(Linhas)": ScrollRenderer["Table Row Heatmap"],
			}))();
		},

		aggregatorsCreate() {
			const usFmt = PivotUtilities.numberFormat();
			const usFmtInt = PivotUtilities.numberFormat({ digitsAfterDecimal: 0 });
			const usFmtPct = PivotUtilities.numberFormat({
				digitsAfterDecimal: 1,
				scaler: 100,
				suffix: "%",
			});

			const aggregators = ((tpl) => ({
				"Contar": tpl.count(usFmtInt),
				"Contar valores exclusivos": tpl.countUnique(usFmtInt),
				"Listar valores exclusivos": tpl.listUnique(", "),
				"Soma": tpl.sum(usFmt),
				"Soma inteiros": tpl.sum(usFmtInt),
				"Média": tpl.average(usFmt),
				"Mediano": tpl.median(usFmt),
				"Variância da amostra": tpl.var(1, usFmt),
				"Desvio Padrão da Amostra": tpl.stdev(1, usFmt),
				"Mínimo": tpl.min(usFmt),
				"Máximo": tpl.max(usFmt),
				"Primeiro": tpl.first(usFmt),
				"Último": tpl.last(usFmt),
				"Soma sobre Soma": tpl.sumOverSum(usFmt),
				"Soma como fração do total": tpl.fractionOf(tpl.sum(),"total",usFmtPct),
				"Soma como fração de linhas": tpl.fractionOf(tpl.sum(), "row", usFmtPct),
				"Soma como fração de colunas": tpl.fractionOf(tpl.sum(),"col",usFmtPct),
				"Contar como fração do total": tpl.fractionOf(tpl.count(),"total",usFmtPct),
				"Contar como fração de linhas": tpl.fractionOf(tpl.count(),"row",usFmtPct),
				"Contar como fração de colunas": tpl.fractionOf(tpl.count(),"col",usFmtPct),
			}))(PivotUtilities.aggregatorTemplates);
			this.aggregators = aggregators;
		},

		localesCreate() {
			let locales = {
				en: {
					aggregators: this.aggregators,
					localeStrings: {
						renderError: "An error occurred while rendering the PivotTable results.",
						computeError: "An error occurred while computing the PivotTable results.",
						uiRenderError: "An error occurred while rendering the PivotTable UI.",
						selectAll: "Select All",
						selectNone: "Select None",
						tooMany: "(too many to list)",
						filterResults: "Filter values",
						totals: "Totals",
						vs: "vs",
						of: "of",
						by: "by",
					},
				},
				br: {
					aggregators: this.aggregators,
					localeStrings: {
						renderError: "Ocorreu um erro ao renderizar os resultados da tabela dinâmica.",
						computeError: "Ocorreu um erro ao calcular os resultados da Tabela Dinâmica.",
						uiRenderError: "Ocorreu um erro ao renderizar a UI da tabela dinâmica.",
						selectAll: "Selecionar tudo",
						selectNone: "Selecione nenhum",
						tooMany: "(muitos para listar)",
						filterResults: "Filtrar valores",
						only: "Apenas",
						totals: "Totais",
						cancel: "Cancelar",
						vs: "vs",
						of: "de",
						by: "por",
					},
				},
			}
			this.locales = locales;
		},
	},

	created() {
		this.renderersCreate();
		this.aggregatorsCreate();
		this.localesCreate();
	},
}
</script>