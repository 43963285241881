<template lang="pug">
div
  v-row.no-gutters.align-center.wrap.justify-end
    v-col(cols="12", md="2")
      v-text-field.mr-1(
        v-model="filters.datainicial",
        color="primary",
        label="Data/Hora inicial",
        type="datetime-local",
        hide-details,
        dense,
        outlined
      )
    v-col(cols="12", md="2")
      v-text-field.mr-1(
        v-model="filters.datafinal",
        color="primary",
        label="Data/Hora final",
        type="datetime-local",
        hide-details,
        dense,
        outlined
      )
    v-btn.mx-1(
      :disabled="loading",
      :loading="loading",
      outlined,
      icon,
      color="primary",
      title="Atualizar",
      @click="getLogs"
    )
      v-icon(small) fa-sync
  v-divider
  DataFilter(:items="logs", :filterProps="filterProps")
    template(v-slot:default="{ items }")
      v-data-table(
        :headers="headers",
        :items="items",
        :loading="loading",
        :items-per-page="items.length",
        :expanded.sync="expanded",
        item-key="time",
        :single-expand="true",
        show-expand,
        dense,
        hide-default-footer
      )
        template(v-slot:item.time="{ item }")
          span {{ formatDate(item.time) }}
        template(v-slot:item.metodo="{ item }")
          v-chip(small, label, outlined) {{ item.tipo }}
        template(v-slot:expanded-item="{ headers, item }")
          td(colspan="10")
            div(class="dv-data") {{ item.data }}
</template>

<script>
import api from '@/api/csapi';

export default {
  name: 'InternalLogs',
  data: () => ({
    loading: false,
    expanded: [],
    headers: [
      { text: 'Data/Hora', value: 'time' },
      { text: 'Tipo', value: 'type' },
      { text: 'Identificador', value: 'identifier' },
      { text: 'Mensagem', value: 'message' },
      { text: '', value: 'data-table-expand' },
    ],
    filterProps: {},
    filters: {
      datainicial: `${new Date().toISOString().split('T')[0]} 00:00`,
      datafinal: `${new Date().toISOString().split('T')[0]} 23:59`,
    },
    logs: [],
  }),
  computed: {
    //
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    mountDataFilters() {
      this.filterProps = {
        includeSearch: true,
        items: [
          {
            text: 'Tipo',
            type: 'select',
            values: this.logs
              .map((item) => item.type)
              .sort()
              .map((item) => ({
                field: 'type',
                text: item,
                value: item,
              })),
          },
          {
            text: 'Identificador',
            type: 'select',
            values: this.logs
              .map((item) => item.identifier)
              .sort()
              .map((item) => ({
                field: 'identifier',
                text: item,
                value: item,
              })),
          },
        ],
      };
    },

    async getLogs() {
      this.loading = true;
      try {
        const { datainicial, datafinal } = this.filters;

        const query = `?datainicial=${new Date(datainicial).toISOString()}&datafinal=${new Date(datafinal).toISOString()}`;

        const res = await api.get(`/logs/internos${query}`);
        this.logs = res.data;
        this.mountDataFilters();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getLogs();
  },
};
</script>

<style scoped>
.dv-data {
  white-space: pre-wrap;
}
</style>
