import Vue from 'vue';
import { io } from 'socket.io-client';

export const socket = io(process.env.VUE_APP_CSAPI_SOCKET_URL, { autoConnect: false });

export const state = Vue.observable({
  connected: false,
  serverStatus: null,
  notificationStatus: false,
  socketEvents: [],
  socketConnections: [],
  eventsReceived: [],
  eventsSent: [],
});

const insertEvent = (event) => {
  state.socketEvents = state.socketEvents.filter((e) => {
    const date = new Date(e.date);
    const now = new Date();
    const diff = now - date;
    const diffInHours = diff / (1000 * 60 * 60);
    return diffInHours < 1;
  });
  state.socketEvents.push(event);
};

socket.on('connect', () => state.connected = true);
socket.on('disconnect', () => {
  state.connected = false;
  state.serverStatus = null;
  state.socketEvents = [];
  state.socketConnections = [];
  state.eventsReceived = [];
  state.eventsSent = [];
});

socket.on('server:stats', (data) => {
  state.serverStatus = data;
});

socket.on('socket:connected', (data) => {
  insertEvent({ event: 'connected', data });
});

socket.on('socket:disconnected', (data) => {
  insertEvent({ event: 'disconnected', data });
});

socket.on('socket:connections', (data) => {
  state.socketConnections = data;
});

socket.on('event:sent', (data) => {
  state.eventsSent.push(data);
  insertEvent({ event: 'sent', data });
});

socket.on('event:received', (data) => {
  state.eventsReceived.push(data);
  insertEvent({ event: 'received', data });
});

socket.on('sysafra:sysbackup:alerta', () => {
  state.notificationStatus = true;
});
