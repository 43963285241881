<template lang="pug">
v-dialog(
  v-if="showDialog",
  max-width="800px",
  v-model="showDialog",
  persistent,
  transition="dialog-transition"
  @close="closeDialog"
)
  v-card.mx-auto
    v-card-title(justify-center)
      h6 {{ dialogValues.title }}

    v-card-text
      .text-h5.text--primary
        | {{ dialogValues.text }}

    v-text-field.mx-4(
      v-if="dialogValues.showTextField"
      :label="dialogValues.textFieldTitle",
      outlined
      v-model="dataProps.motivo"
      required
      :rules="[...dialogValues.requiredTextField ? [rules.required, rules.min] : [] ]"
    )
    v-card-actions
      v-spacer
      v-btn.ma-2(
        v-for="btn in dialogValues.buttons",
        justify-end,
        :color="btn.color",
        :key="btn.name",
        @click="btn.onClick(dataProps)"
      ) {{ btn.title }}
        v-icon(v-if="btn.icon", left) {{ btn.icon }}
</template>
<script>


export default {

  data() {

    return {
      rules: {
        required: (value) => !!value || 'Campo obrigatório.',
        min: (v) => v.length >= 30 || 'Mínimo de 30 caracteres',
      },

      dataProps: {
        motivo: ''
      }
    }
  },

  methods: {
    closeDialog() {
      this.dataProps.motivo = '';
    },
  },
  computed: {
    showDialog() {
      return this.$store.state.dialog.show;
    },
    dialogValues() {
      return this.$store.state.dialog.values;
    },
  },
  watch: {
    $route() {
      this.$store.dispatch('alert/clear');
    },
  },
};
</script>
