<template lang="pug">
.loader-container(v-if="show")
  .content-container
    .content
      v-progress-circular(:size="100", color="green", indeterminate)
      h1 {{ text }}
</template>

<script>
export default {
  computed: {
    show() {
      return this.$store.state.loader.show;
    },
    text() {
      return this.$store.state.loader.text || "Carregando...";
    },
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}

.content-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    ellipse closest-side,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.1) 70%,
    rgba(255, 255, 255, 0.2) 90%
  );
  pointer-events: none;
  border-radius: 10px;
  backdrop-filter: blur(5px);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: larger;
  z-index: 999;
}
</style>
