<template lang="pug">
div(style="height: 100%; overflow-y: auto; text-align: center; "
  :style="'align-content:' + (dataset.length == 0 ? 'center' : 'start;')"
  )

  div(v-for="(field, index) in dataset" :key="index" class="ma-0 pa-0")
    ListJson(:item="field" :id="index" @command="commandModelo" :disabled="disabled" :singlelayer="singlelayer")

  div(class="ma-2 pa-0")

    div(v-if="dataset.length == 0" class="ma-0 pa-0 pb-4")
      span O modelo está vazio. Clique no botão abaixo para adicionar um novo campo.
    div
      v-btn(
        :disabled="disabled"
        outlined
        color="primary"
        @click="adicionarItem"
      )
        v-icon(left) mdi mdi-plus
        span  Adicionar


</template>

<script>
import ListJson from './ModeloJson_ListJson'

export default {
  name: 'ModeloJson',
  components: {
    ListJson
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    singlelayer: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dataset: [],
    }
  },

  watch: {
    items () {
      this.dataset = this.datasetPadronizeIn(JSON.parse(JSON.stringify(this.items)));
    },
    dataset(value) {
      setTimeout(() => {
        this.$emit('input', this.datasetPadronizeOut(JSON.parse(JSON.stringify(value))))
      }, 10)

    }
  },

  methods: {
    emitAction(command) {
      this.$emit('command', command, this.item);
    },

    adicionarItem() {
      this.dataset.push({
        id: this.generateId(),
        key: false,
        edit: true,
        typemain: '',
        name: '',
        type: ''
      })
    },

    commandModelo(action, item) {
      switch (action) {
        case 'addup':
          this.addItem(this.dataset, item, 'up')
          break
        case 'adddown':
          this.addItem(this.dataset, item, 'down')
          break
        case 'addpk':
          this.controlaPk(this.dataset, item, true)
          break
        case 'removepk':
          this.controlaPk(this.dataset, item, false)
          break
        case 'moveup':
          this.moveDownOrUp(this.dataset, item, 'up')
          break
        case 'movedown':
          this.moveDownOrUp(this.dataset, item, 'down')
          break
        case 'edit':
          this.editItem(item)
          break
        case 'deleteitem':
          this.deleteItem(this.dataset, item)
          break
        case 'saveitem':
          this.saveItem(this.dataset, item)
          break
        case 'canceledititem':
          this.cancelEditItem(this.dataset, item)
          break
        case 'addnode':
          this.addnode(this.dataset, item)
          break
      }
    },

    editItem(item) {
      item.edit = true
      delete item.oldItem
      item.oldItem = JSON.parse(JSON.stringify(item))
    },

    saveItem(json, item) {
      json.forEach((row, index) => {
        if (row.id === item.id) {
          row.edit = false
          if (row.oldItem) {
            delete row.oldItem
          }

          if (row.type == 'object' || row.type == 'array' || row.type == 'array_primitive') {
            if (!row.fields) {
              row.fields = []
            }
          }

          this.atualizamodelo(json)
        } else if (row.fields) {
          this.saveItem(row.fields, item)
        }
      })

      this.dataset = JSON.parse(JSON.stringify(json))
    },

    cancelEditItem(json, item) {
      if (!item.oldItem) {
        this.deleteItem(json, item)
      } else {
        json.forEach((row) => {
          if (row.id == item.id) {
            row.edit = false
            row.name = row.oldItem.name
            row.type = row.oldItem.type
            if (row.oldItem) {
              delete row.oldItem
            }
          } else if (row.fields) {
            this.cancelEditItem(row.fields, item)
          }
        })
      }
    },

    deleteItem(json, item) {
      json.forEach((row, index) => {
        if (row.id == item.id) {
          json.splice(index, 1)
        } else if (row.fields) {
          this.deleteItem(row.fields, item)
        }
      })

      this.atualizamodelo(json)
    },

    controlaPk(json, item, addPk) {
      json.forEach(row => {
        if (row.id == item.id) {
          row.key = addPk
        } else if (row.fields) {
          this.controlaPk(row.fields, item, addPk)
        }
      })      
      
      this.atualizamodelo(json)
    },

    moveDownOrUp(json, item, direction) {

      json.forEach((row, index) => {
        if (row.id == item.id) {
          if (direction === 'up') {
            json.splice(index, 1)
            json.splice(index - 1, 0, item)
          } else {
            json.splice(index, 1)
            json.splice(index + 1, 0, item)
          }
        } else if (row.fields) {
          this.moveDownOrUp(row.fields, item, direction)
        }
      })

      this.atualizamodelo(json)
    },

    addnode(json, item) {
      json.forEach((row) => {
        if (row.id == item.id) {
          if (!row.fields) {
            row.fields = []
          }
          
          if (item.type == 'arrayprimitive') {
            if (row.fields.length > 0) {
              this.$toast.error('O tipo array primitivo só permite um campo filho')
              return 
            }
          }

          row.fields.unshift({
            id: this.generateId(),
            key: false,
            edit: true,
            name: '',
            type: '',
            typemain: item.type
          })

          this.atualizamodelo(json)
        } else if (row.fields) {
          this.addnode(row.fields, item)
        }
      })

      this.dataset = JSON.parse(JSON.stringify(json))
    },

    addItem(json, item, direction) {
      let inserted = false

      this.atualizamodelo(json)

      json.forEach((field, index) => {
        if (field.id == item.id && !inserted) {
          inserted = true

          json.splice(direction === 'up' ? index : index + 1, 0, {
            id: this.generateId(),
            key: false,
            edit: true,
            typemain: item.type,
            name: '',
            type: ''
          })
        } else if (field.fields) {
          this.addItem(field.fields, item, direction)
        }
      })
    },

    datasetPadronizeIn(json, typemain = '') {
      json.forEach((item) => {
        item.id = this.generateId();
        item.edit = false;
        item.typemain = typemain;

        item.key = item.key ? item.key : false;

        if (item.from) {
          item.fields = [{...item.from}];
          item.type = 'arrayprimitive';
          delete item.from;
        }

        if (item.fields) {
          item.typemain = item.type;
          this.datasetPadronizeIn(item.fields, item.type);
        }
      });

      return json;
    },

    datasetPadronizeOut(json) {
      json.forEach((item, index) => {
        if (item.name === '') {
          json.splice(index, 1);
        }
      });

      json.forEach((item) => {
        if (item.type == 'arrayprimitive') {
          if (item.fields && item.fields.length > 0) {
            item.from = { name: item.fields[0].name, type: item.fields[0].type};
          }
          delete item.fields;
        }

        delete item.id;
        delete item.edit;
        delete item.oldItem;
        delete item.typemain;

        if (item.key == false) delete item.key;

        if (item.fields) {
          this.datasetPadronizeOut(item.fields);
        }
      });
      return json
    },

    atualizamodelo(json) {
      json.push({
        id: this.generateId(),
        key: false,
        edit: true,
        typemain: '',
        name: '',
        type: ''
      })

      json.pop()
    },

    generateId() {
      return Math.random().toString(36).substr(2, 9);
    },
  },
}
</script>