var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"no-gutters align-center wrap justify-end"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"mr-1",attrs:{"color":"primary","label":"Data/Hora inicial","type":"datetime-local","hide-details":"","dense":"","outlined":""},model:{value:(_vm.filters.datainicial),callback:function ($$v) {_vm.$set(_vm.filters, "datainicial", $$v)},expression:"filters.datainicial"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"mr-1",attrs:{"color":"primary","label":"Data/Hora final","type":"datetime-local","hide-details":"","dense":"","outlined":""},model:{value:(_vm.filters.datafinal),callback:function ($$v) {_vm.$set(_vm.filters, "datafinal", $$v)},expression:"filters.datafinal"}})],1),_c('v-btn',{staticClass:"mx-1",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"outlined":"","icon":"","color":"primary","title":"Atualizar"},on:{"click":_vm.getLogs}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-sync")])],1)],1),_c('v-divider'),_c('DataFilter',{attrs:{"items":_vm.logs,"filterProps":_vm.filterProps},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":items,"loading":_vm.loading,"items-per-page":items.length,"expanded":_vm.expanded,"item-key":"data_hora","single-expand":true,"show-expand":"","dense":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data_hora",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.data_hora)))])]}},{key:"item.metodo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":"","outlined":""}},[_vm._v(_vm._s(item.metodo))])]}},{key:"item.duracao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.duracao)+" ms")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorByStatus(item.status),"text-color":_vm.getColorByStatus(item.status),"small":"","label":"","outlined":""}},[_vm._v(_vm._s(item.status))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(item.usuario))])]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }