<template lang="pug">
v-row.pa-1()
  v-col(cols="12")
    v-row(no-gutters, justify="start")
      apexchart(
        type="area"
        :options="chartOptions"
        :series="formattedChartData"
        style="width: 100%;"
        height="360"
      )
</template>

<script>
export default {
  name: "BackupSizeChart",
  props: {
    chartData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      exampleData: [
        {
          name: "Example Series 1",
          data: [
            { x: new Date("2021-01-01").getTime(), y: 31 },
            { x: new Date("2021-02-01").getTime(), y: 40 },
            { x: new Date("2021-03-01").getTime(), y: 28 },
            { x: new Date("2021-04-01").getTime(), y: 51 },
            { x: new Date("2021-05-01").getTime(), y: 42 },
            { x: new Date("2021-06-01").getTime(), y: 109 },
            { x: new Date("2021-07-01").getTime(), y: 100 },
            { x: new Date("2021-08-01").getTime(), y: 54 },
            { x: new Date("2021-09-01").getTime(), y: 85 },
            { x: new Date("2021-10-01").getTime(), y: 92 },
            { x: new Date("2021-11-01").getTime(), y: 73 },
            { x: new Date("2021-12-01").getTime(), y: 120 },
            { x: new Date("2022-01-01").getTime(), y: 62 },
            { x: new Date("2022-02-01").getTime(), y: 72 },
            { x: new Date("2022-03-01").getTime(), y: 81 }
          ]
        },
        {
          name: "Example Series 2",
          data: [
            { x: new Date("2021-01-01").getTime(), y: 11 },
            { x: new Date("2021-02-01").getTime(), y: 32 },
            { x: new Date("2021-03-01").getTime(), y: 45 },
            { x: new Date("2021-04-01").getTime(), y: 32 },
            { x: new Date("2021-05-01").getTime(), y: 34 },
            { x: new Date("2021-06-01").getTime(), y: 52 },
            { x: new Date("2021-07-01").getTime(), y: 41 },
            { x: new Date("2021-08-01").getTime(), y: 38 },
            { x: new Date("2021-09-01").getTime(), y: 49 },
            { x: new Date("2021-10-01").getTime(), y: 62 },
            { x: new Date("2021-11-01").getTime(), y: 45 },
            { x: new Date("2021-12-01").getTime(), y: 58 },
            { x: new Date("2022-01-01").getTime(), y: 37 },
            { x: new Date("2022-02-01").getTime(), y: 43 },
            { x: new Date("2022-03-01").getTime(), y: 58 }
          ]
        }
      ],
      exampleColors: ["#B0BEC5", "#37474F"]
    };
  },

  computed: {
    formattedChartData() {
      return this.chartData.length > 0 ? this.chartData : this.exampleData;
    },

    chartColors() {
      return this.chartData.length > 0 ? ["#65e3a1", "#ff7842"] : this.exampleColors;
    },

    chartOptions() {
      return {
        grid: {
          padding: {
            right: 10,
            bottom: 10,
            left: 40
          }
        },
        chart: {
          type: "area",
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "center",
          fontSize: "14px"
        },
        colors: this.chartColors,
        stroke: {
          width: 2,
          curve: "straight"
        },
        fill: {
          type: "solid",
          opacity: 0.9
        },
        xaxis: {
          type: "category",
          labels: {
            show: true,
            style: {
              colors: "#237cd4",
              fontSize: "16px"
            }
          },
          crosshairs: {
            show: true,
            width: 1,
            opacity: 1,
            position: "front",
            stroke: {
              width: 1,
              dashArray: 0
            }
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: this.chartColors ? ['#177d47', '#c13700'] : this.exampleColors
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 5,
            opacity: 0.8,
            borderColor: "#fff"
          },
          formatter: val => this.bytesToMegabytes(val)
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd/MM/yyyy"
          },
          y: {
            formatter: val => this.bytesToMegabytes(val),
            title: {
              formatter: seriesName => seriesName + ":"
            }
          },
          title: {
            formatter: seriesName => seriesName + ":"
          }
        }
      };
    }
  },

  methods: {
    bytesToMegabytes(bytes) {
      if (bytes === 0) return "0 Bytes";

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));

      const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);

      return `${formattedSize} ${sizes[i]}`;
    }
  }
};
</script>
