<template lang="pug">
div
  v-row.no-gutters.align-center.wrap.justify-end
    v-col(cols="12", md="2")
      v-text-field.mr-1(
        v-model="filters.datainicial",
        color="primary",
        label="Data/Hora inicial",
        type="datetime-local",
        hide-details,
        dense,
        outlined
      )
    v-col(cols="12", md="2")
      v-text-field.mr-1(
        v-model="filters.datafinal",
        color="primary",
        label="Data/Hora final",
        type="datetime-local",
        hide-details,
        dense,
        outlined
      )
    v-btn.mx-1(
      :disabled="loading",
      :loading="loading",
      outlined,
      icon,
      color="primary",
      title="Atualizar",
      @click="getLogs"
    )
      v-icon(small) fa-sync
  v-divider
  DataFilter(:items="logs", :filterProps="filterProps")
    template(v-slot:default="{ items }")
      v-data-table(
        :headers="headers",
        :items="items",
        :loading="loading",
        :items-per-page="items.length",
        :expanded.sync="expanded",
        item-key="data_hora",
        :single-expand="true",
        show-expand,
        dense,
        hide-default-footer
      )
        template(v-slot:item.data_hora="{ item }")
          span {{ formatDate(item.data_hora) }}
        template(v-slot:item.metodo="{ item }")
          v-chip(small, label, outlined) {{ item.metodo }}
        template(v-slot:item.duracao="{ item }")
          span {{ item.duracao }} ms
        template(v-slot:item.status="{ item }")
          v-chip(
            :color="getColorByStatus(item.status)",
            :text-color="getColorByStatus(item.status)",
            small,
            label,
            outlined
          ) {{ item.status }}

        template(v-slot:expanded-item="{ headers, item }")
          pre {{ item.usuario }}
</template>

<script>
import api from "@/api/csapi";

export default {
  name: "SocketLogs",
  data: () => ({
    loading: false,
    expanded: [],
    headers: [
      { text: "Data", value: "data_hora" },
      { text: "Hash", value: "hash" },
      { text: "IP", value: "ip" },
      { text: "User Agent", value: "app_type" },
      { text: "Evento", value: "evento" },
      { text: "Mensagem", value: "message" },
      { text: "", value: "data-table-expand" }
    ],
    filterProps: {},
    filters: {
      datainicial: `${new Date().toISOString().split("T")[0]} 00:00`,
      datafinal: `${new Date().toISOString().split("T")[0]} 23:59`,
      type: null,
      ip: null,
      status: null
    },
    logs: []
  }),
  computed: {
    //
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    mountDataFilters() {
      this.filterProps = {
        includeSearch: true,
        items: [
          {
            text: "IP",
            type: "select",
            values: this.logs
              .map(item => item.ip)
              .sort()
              .map(item => ({
                field: "ip",
                text: item,
                value: item
              }))
          },
          {
            text: "User Agent",
            type: "select",
            values: this.logs
              .map(item => item.app_type)
              .sort()
              .map(item => ({
                field: "app_type",
                text: item,
                value: item
              }))
          },
          {
            text: "Evento",
            type: "select",
            values: this.logs
              .map(item => item.evento)
              .sort()
              .map(item => ({
                field: "evento",
                text: item,
                value: item
              }))
          }
        ]
      };
    },

    async getLogs() {
      this.loading = true;
      try {
        const res = await api.post("/__internals__/logs/socket", this.filters);
        this.logs = res.data.map(log => ({
          ...log,
          hash: log.usuario.hash
        }));

        this.mountDataFilters();

        this.logs = this.logs.sort((a, b) => new Date(b.data_hora) - new Date(a.data_hora));
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.getLogs();
  }
};
</script>
