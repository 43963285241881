<template lang="pug">
v-card(
  elevation="0"
  :height="height"
  :width="width"
)
  v-row.no-gutters.justify-center.align-center
    v-col(align-content-center justify-center)
      apexchart(
        :options="chartOptions(chartType, title, labels)"
        :series="dataset"
      )
</template>

<script>
export default {
  name: 'Chart',
  props: {
    chartType: {
      type: String,
      validator: (value) => ['bar', 'horizontalBar', 'pie', 'donut', 'line'].includes(value),
    },
    title: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number | String,
      default: 340,
    },
    width: {
      type: Number | String,
      default: '',
    },
  },

  methods: {
    chartOptions(type, title, labels) {
      return {
        labels,
        chart: {
          type,
        },
        title: {
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 2,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238',
          },
          text: title,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1, 2, 3, 4, 5, 6],
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
          background: {
            enabled: true,
            foreColor: '#666',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#666',
            opacity: 0.9,
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          fontSize: '11px',
          fontFamily: 'Helvetica, Arial',
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          y: {
            formatter: (value) => value,
            // title: {
            //   formatter: (value) => value
            // }
          },
        },
        xaxis: {
          tooltip: { enabled: false },
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            maxHeight: 140,
            minHeight: true ? 55 : undefined,
            formatter: (value) => value,
            style: {
              colors: [],
              fontSize: '11px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            dataLabels: {
              hideOverflowingLabels: true,
              position: 'top',
              formatter: (value) => value,
            },
          },
        },
      };
    },
  },
};
</script>
