import { set, toggle } from '@/utils/vuex';

export default {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  isControl: set('isControl'),
  setAccessSySafra: set('accessSySafra'),
  setTitle: set('title'),
};
