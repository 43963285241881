import jwt from 'jsonwebtoken';
import axios from 'axios';

const baseUrl = process.env.VUE_APP_PROD_API;
const headers = {};

const send = (method) => (action, data = {}) => axios({
  method,
  url: baseUrl + action,
  headers,
  data,
});

function getTempToken() {
  return jwt.sign(
    {},
    process.env.VUE_APP_PROD_TEMP_SECRET,
    {
      algorithm: process.env.VUE_APP_PROD_AUTH_ALG,
      expiresIn: process.env.VUE_APP_PROD_AUTH_EXP,
    },
  );
}

export default {
  refreshToken: (auth) => headers.Authorization = `Bearer ${auth.auth || getTempToken()}`,
  get: (action) => send('get')(action, {}),
  put: (action, data = {}) => send('put')(action, data),
  post: (action, data = {}) => send('post')(action, data),
};
