<template lang="pug">
div(v-if="alert.message")
</template>

<script>
export default {
  computed: {
    alert() {
      const { alert } = this.$store.state;

      if (alert.message) {
        if (alert.type === 'error') {
          this.$toast.error(alert.message, {
            timeout: false,
            onClick: () => this.$store.dispatch('alert/clear'),
          });
        } else if (alert.type === 'success') {
          this.$toast.success(alert.message, {
            timeout: false,
            onClick: () => this.$store.dispatch('alert/clear'),
          });
        } else if (alert.type === 'info') {
          this.$toast.info(alert.message, {
            timeout: false,
            onClick: () => this.$store.dispatch('alert/clear'),
          });
        } else if (alert.type === 'warning') {
          this.$toast.warning(alert.message, {
            timeout: false,
            onClick: () => this.$store.dispatch('alert/clear'),
          });
        }
      }

      return alert;
    },
  },
  methods: {
    clear() {
      this.$store.dispatch('alert/clear');
    },
  },
  watch: {
    // $route (to, from){
    $route() {
      this.$store.dispatch('alert/clear');
    },
  },
};
</script>
