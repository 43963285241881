<template lang="pug">
v-img(
  src="/img/logos/MarcaControlSoftNova.png"
  :position="(left ? 'left' : 'center') + ' center'"
  contain
  :height="h"
  :width="w"
  :max-height="mh"
  :max-width="mw"
)
</template>

<script>
export default {
  name: 'LogoControlSoft',
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    h: {
      type: Number,
      default: 40,
    },
    w: {
      type: Number,
      default: 250,
    },
    mh: {
      type: Number,
      default: 50,
    },
    mw: {
      type: Number,
      default: 250,
    },
  },
};
</script>
