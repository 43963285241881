import { authProd, authHom } from '@/api';

import csapi from '@/api/csapi';
import router from '@/router';
import jwt from 'jsonwebtoken';

export default {
  async login({ dispatch, commit, state }, data) {
    let tk = null;

    try {
      authProd.refreshAuthProd(state);
      tk = await authProd.login(data);

      if (!tk && !tk.token) { throw new Error('erro ao tentar logar'); }

      commit('loginSuccess', tk);
    } catch (e) {
      switch (e.response.status) {
        case 401:
          dispatch('alert/error', 'Usuario ou senha invalidos', { root: true });
          break;
        case 403:
          dispatch('alert/error', 'Falha de comunicação com Auth e API.', { root: true });
          break;
        default:
          dispatch('alert/error', 'Falha geral na comunicação com API. Entre em contato com o time de tecnologia', { root: true });
          break;
      }
      return;
    }

    try {
      authHom.refreshAuthHom(state);
      const tw = await authHom.login(data);
      tk.token2 = tw.token;
      commit('loginSuccess', tk);
    } catch (error) {
      dispatch('alert/info', 'Não foi possivel conectar ao ambiente secundario', { root: true });
      return;
    }

    router.push('/dashboard');
  },

  async csApiLogin({ dispatch, commit, state }, data) {
    try {
      const reqToken = jwt.sign({}, process.env.VUE_APP_CSAPI_PUBLIC_KEY, { algorithm: 'HS512' });
      csapi.addHeader('Authorization', `Bearer ${reqToken}`);
      const body = {
        key: process.env.VUE_APP_CSAPI_LOGIN_KEY,
        user: state.user,
      };
      const res = await csapi.post('/v1/auth/login', body);
      commit('setCSAPI', res.data);
    } catch (error) {
      console.error(error);
      dispatch('alert/info', 'Não foi possivel conectar à CSAPI', { root: true });
    }
  },

  logout({ commit }) {
    commit('logout');
  },

  verify({ state }) {
    if ((!state.auth) || (!state.user)) {
      router.push('/logout');
    }
  },

  checkLogin({ state }) {
    if ((state.auth) && (state.user)) {
      router.push('/dashboard');
    }
  },
};
