<template lang="pug">
div
  v-card(v-if="loaded")
    v-card-title(class="mw-50")
      v-text-field(
        id="buscarDataTable"
        v-model="search"
        color="success"
        append-icon="mdi-magnify"
        label="Buscar"
        class="search"
        single-line
        hide-details)
      v-btn(
        v-if="!!addUrl"
        :to="addUrl"
        color="success"
        outlined
        class="ml-2 white--text"
      ) Adicionar
        v-icon( right ) mdi-plus-circle

      v-btn(
        v-if="exportable"
        color="success"
        outlined
        class="ml-2 white--text"
        @click="exportToExcel"
      ) Excel
        v-icon( right ) fa-file-excel

    v-divider
    v-data-table(
      :headers="fieldsVisiveis",
      :items="dados",
      :loading="!loaded",
      :footer-props="footerProps"
      :search="search",
      :item-key="chave"
      @current-items="emitCurrentItems"
    )

      template(slot="headerCell", slot-scope="{ header }")
        span(class="subheading font-weight-regular text-success text--darken-3") {{ header.text }}

      template(v-slot:item.actions="{ item }")
        v-btn(
          :icon="button.text === null"
          class="mr-3"
          :rounded="button.text !== null"
          :outlined="button.text !== null"
          :small="button.text !== null"
          v-for="button in item.actions"
          :color="button.color"
          @click="retorno(button.name, item)"
          v-bind:key="item.name"
        ) {{button.text}}
          v-icon(:right="button.text !== null") {{button.icon}}

      template(v-slot:item="props")
        tr(@click="props.expanded = !props.expanded")
          td(v-for="field in fieldsVisiveis", :key="field.value")
            v-btn(
              v-if="(field.buttons && field.get(field, props.item).value == true)"
              v-for="button in field.get(field, props.item).buttons"
              class="mr-3"
              :outlined="button.text !== null"
              :small="true"
              :color="button.color"
              :title="button.title"
              v-bind:key="button.name"
              v-on:click="button.internalEvent ? $emit(button.eventName, props.item) : button.onClick(props.item)"
            )
              v-icon() {{button.icon}}

            router-link(:to="field.get(field, props.item).to", tag="a", v-if="field.link")
              | {{ field.get(field, props.item).value }}

            span(v-if="!field.link && !field.icon && !field.buttons")
              | {{ field.get(field, props.item).value }}

            div( class="row no-gutters")
              v-icon(v-if="field.icon" :color="field.get(field, props.item).color" :title="field.get(field, props.item).title")
                |  {{ field.get(field, props.item).icon }}

              div( class="ml-2" ) {{ field.get(field, props.item).title }}

      template(v-slot:no-data)
        v-card(class="elevation-0 pa-15")
          div Nenhum registro encontrado
          v-btn(:to="addUrl" v-if="!!addUrl" color="success" outlined class="ma-2 white--text") Adicionar
            v-icon(right) mdi-plus-circle

      template(v-slot:expand="props", v-if="fieldsExtras.length")
        tr
          th(v-for="field in fieldsExtras", :key="field.value")
            | {{ field.text }}
        tr
          td(v-for="field in fieldsExtras", :key="field.value")
            router-link(:to="field.get(field, props.item).to", tag="a", v-if="field.link")
              | {{ field.get(field, props.item).value }}
            span(v-if="!field.link && !field.icon")
              | {{ field.get(field, props.item).value }}

      template(v-slot:footer.page-text, v-if="footerButtons && footerButtons.length > 0" v-for="button in footerButtons")
        v-btn(
          :color="button.color"
          :to="button.to"
        ) {{ button.text }}
          v-icon(:right="true") {{ button.icon }}

</template>

<script>

import ExcellentExport from 'excellentexport';

export default {
  props: [
    'fields',
    'dados',
    'loaded',
    'order',
    'footerButtons',
    'addUrl',
    'exportable',
    'excelFileName',
  ],

  data: () => ({
    search: '',
    pagination: {},
    chave: '',
    footerProps: { itemsPerPageText: 'Itens por página', itemsPerPageOptions: [15, 100, { text: 'Todos', value: -1 }] },
    rowsPerPage: [50, 100, { text: 'Todos', value: -1 }],
    fieldsVisiveis: {},
    fieldsExtras: {},
  }),

  mounted() {
    this.fieldsVisiveis = this.fields.filter((f) => !(f.extra));
    this.fieldsExtras = this.fields.filter((f) => f.extra);
    this.pagination = this.order;
    this.chave = this.fields[0].value;
  },

  methods: {
    emitCurrentItems(value) {
      this.$emit('current-items', value);
    },

    exportToExcel() {
      const link = document.createElement('a');
      ExcellentExport.excel(link, document.getElementsByTagName('table')[0], 'pagina 1');
      link.download = this.excelFileName ?? 'gifnoc-excel.xls';
      link.click();
    },
  },
};
</script>

  <style>
    .margin-200{
      margin-top:200px;
    }
    .search:focus-within {
      width: 400px;
    }
    .search-width {
      width: 0 !important;
    }
    td.label{
      height:45px !important;
    }
  </style>
