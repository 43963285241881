<template lang="pug">
div
  v-row(
    v-if="item.edit == true"
    no-gutters 
    style="border: 1px solid #e0e0e0; border-radius: 5px;" class="ma-2")

    v-col(cols="5" md="5")
      v-text-field(
        :ref="item.id + 'name'"
        :id="item.id + 'name'"
        placeholder="Campo"
        v-model="item.name"
        outlined
        dense
        hide-details
        @keyup.native="formatInput(item)"
        @paste="onPaste($event, item)"
        class="pt-1 pl-2 pr-1 pb-1"
      )

    v-col(cols="4" md="4")
      v-select(
        :ref="item.id + 'type'"
        :id="item.id + 'type'"
        v-model="item.type"
        :item-text="item => item.charAt(0).toUpperCase() + item.slice(1)"
        :items="singlelayer ? types.singlelayer : item.fields ? types.object : item.typemain == 'arrayprimitive' ? types.arrayprimitive : types.all"
        :disabled="item.fields || item.from ? true : false"
        outlined
        dense
        hide-details
        placeholder="Tipo"
        class="pt-1 pl-2 pr-1 pb-1"
      )

    v-col(cols="3" md="3" style="text-align: end;" class="pr-2")
      v-btn(
        color="primary"
        icon
        @click="saveItem(item)"
        )
        v-icon mdi mdi-check

      v-btn(
        color="primary"
        icon
        @click="cancelItem(item)"
        )
        v-icon mdi mdi-close
        
  div
    v-list-item(dense v-if="!item.edit")
      v-list-item-content(style="text-align: start;")
        v-list-item-title {{ item.name }} {{ item.key ? '🔑' : '' }}
        v-list-item-subtitle {{ item.type }}
      v-list-item-action(v-if="!disabled" class="ma-0 pa-0")
        v-menu(offset-y class="ma-0 pa-0")
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon small v-bind="attrs" v-on="on")
              v-icon mdi mdi-dots-vertical
          v-list(dense class="ma-0 pa-0")
            v-list-item(v-for="action in (item.type == 'object' || item.type == 'array' || item.type == 'arrayprimitive'? actionsForObjects : item.typemain == 'arrayprimitive' ? actionsForArrayPrimitive : actionsForSimples)"
              @click="emitAction(action.command, item)"
              :style="action.divider ? 'border-bottom: 1px solid #e0e0e0' : ''")
              v-list-item-icon  
                v-icon {{ action.icon }}
              v-list-item-title 
                | {{ action.title }}
    v-divider
    div(v-if="(item.type == 'object' || item.type == 'array' || item.type == 'arrayprimitive') && item.fields")
      div(v-for="(row, id) in item.fields")
        ListJson(:item="row" class="ml-6" @command="emitAction" :id="id" :disabled="disabled")

</template>

<script>
import { mdiAllInclusive } from '@mdi/js'

export default {
  name: 'ListJson',
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    singlelayer: {
      type: Boolean,
      default: true,
    },
  },
  
  data() {
    return {
      actionsForSimples: [
        { title: 'Adicionar acima', icon: 'mdi mdi-plus-box-outline', command: 'addup', divider: false },
        { title: 'Adicionar abaixo', icon: 'mdi mdi-plus-box-outline', command: 'adddown', divider: true },
        { title: 'Definir chave primária', icon: 'mdi mdi-key-plus', command: 'addpk', divider: false },
        { title: 'Remover chave primária', icon: 'mdi mdi-key-minus', command: 'removepk', divider: true },
        { title: 'Mover p/ cima', icon: 'mdi mdi-chevron-up-circle-outline', command: 'moveup', divider: false },
        { title: 'Mover p/ baixo', icon: 'mdi mdi-chevron-down-circle-outline', command: 'movedown', divider: true },
        { title: 'Editar', icon: 'mdi mdi-pencil', command: 'edit', divider: false },
        { title: 'Remover', icon: 'mdi mdi-delete-outline', command: 'deleteitem', divider: false },
      ],
      actionsForObjects: [
        { title: 'Adicionar campo', icon: 'mdi mdi-plus-box-outline', command: 'addnode', divider: true },
        { title: 'Mover p/ cima', icon: 'mdi mdi-chevron-up-circle-outline', command: 'moveup', divider: false },
        { title: 'Mover p/ baixo', icon: 'mdi mdi-chevron-down-circle-outline', command: 'movedown', divider: true },
        { title: 'Editar', icon: 'mdi mdi-pencil', command: 'edit', divider: false },
        { title: 'Remover Nó', icon: 'mdi mdi-delete-outline', command: 'deleteitem', divider: false },
      ],
      actionsForArrayPrimitive: [
        { title: 'Editar', icon: 'mdi mdi-pencil', command: 'edit', divider: false },
      ],
      types: {
        singlelayer: ['string', 'integer', 'bool', 'float'],  
        arrayprimitive: ['string', 'integer', 'bool', 'float'],
        object: ['object', 'array'],
        all: ['string', 'integer', 'bool', 'float', 'object', 'array', 'arrayprimitive']
      }
    }
  },
  methods: {
    emitAction(command, item) {
      this.$emit('command', command, item);
    },

    formatInput(item) {
      const keyCode = event.keyCode;

      if (!((keyCode >= 65 && keyCode <= 90) || keyCode === 8)) {
        event.preventDefault();
      }

      item.name = item.name.replace(/[^a-z0-9]/g, '');
    },

    onPaste(event, item) {
      const text = event.clipboardData.getData('text');
      if (text.length > 32) {
        this.$toast.error('Texto muito grande, máximo de 32 caracteres');
        event.preventDefault();
      } else {
        event.preventDefault();
        // replace permitir numeros e letras minusculas
        item.name = text.toLowerCase().replace(/[^a-z0-9]/g, '');
        // this.formatInput({name: text.toLowerCase()});
      }
    },

    saveItem(item) {
      if (!item.name) {
        this.$toast.error('Campo é obrigatório o preenchido')
        this.$refs[item.id + 'name'].focus();
        return;
      }

      if (!item.type) {
        this.$toast.error('Campo tipo é obrigatório')
        this.$refs[item.id + 'type'].focus();
        return
      }

      this.$emit('command', 'saveitem', item);
    },

    cancelItem(item) {
      item.edit = false;
      this.$emit('command', 'canceledititem', item);
    }
  },
}
</script>