export default {
  show(state, values) {
    state.values = values;
    state.show = true;
  },
  hide(state) {
    state.values = null;
    state.show = false;
  },
};
