export default {
  success(state, message) {
    state.type = 'success';
    state.message = message;
    state.show = true;
  },
  info(state, message) {
    state.type = 'info';
    state.message = message;
    state.show = true;
  },
  warning(state, message) {
    state.type = 'warning';
    state.message = message;
    state.show = true;
  },
  error(state, message) {
    state.type = 'error';
    state.message = message;
    state.show = true;
  },
  clear(state) {
    state.type = null;
    state.message = null;
    state.show = false;
  },
};
