/* eslint-disable quotes */
/* eslint-disable no-undef */
import { versaoAgendamento as agendamento } from '@/api';

const modulos = ['Agro', 'PDV', 'Agenda', 'Contabilidade',
  'EscritaFiscal', 'Self', 'Tarefas', 'Produtos', 'Conector', 'Romaneio', 'SpyUser', 'Fones', 'Liberação Supervisor', 'Tributação', 'Cubo', 'Comercialização', 'Lalur'];
const sistemas = ['Windows 2000', 'Windows XP', 'Windows Vista', 'Windows 7', 'Windows 8', 'Windows 8.1', 'Windows 10', 'Windows 11'];

const getLink = (to) => ({ value }, item) => ({ value: item[value], to: to(item) });
const getBtnLink = (getButtons) => ({ value }, item) => ({ value: item[value], buttons: getButtons(item) });

const get = ({ value }, item) => ({ value: item[value] });
// const getSub = sub => ({value}, item) => ({value: item[sub][value]})
const getDate = ({ value }, item) => ({ value: new Date(item[value]).toLocaleString() });
const getDateOnly = ({ value }, item) => ({
  value: new Date(item[value]).toISOString().split('T')[0].split('-').reverse().join('/'),
});

const getBool = ({ value }, item) => ({ value: item[value] ? '✔️' : '❌' });

const getSubDate = (sub) => ({ value }, item) => ({ value: new Date(item[sub][value]).toLocaleString() });
const getSubLink = (to, sub) => ({ value }, item) => ({ value: item[sub][value], to: to(item) });

const getModulo = ({ value }, item) => ({ value: modulos[item[value] - 1] });
const getSistema = ({ value }, item) => ({ value: sistemas[item[value] - 1] });

const fields = ({
  name, text, align = 'start', fn = get, sort = 0, link = 0, desc = 1, extra = 0, buttons = 0, icon = 0, width,
}) => ({
  value: name, text, sortable: sort, align, link, get: fn, desc, extra, buttons, icon, width,
});

const statusProps = {
  AGUARDANDO: {
    value: 0, icon: 'fa-hourglass', title: 'Aguardando', color: 'secondary',
  },
  BAIXANDO: {
    value: 1, icon: 'fa-spinner', title: 'Baixando', color: 'secondary',
  },
  BAIXADOS: {
    value: 2, icon: 'fa-file-arrow-down', title: 'Baixados', color: 'secondary',
  },
  EXECUTANDO: {
    value: 3, icon: 'fa-hourglass-half', title: 'Executando', color: 'primary',
  },
  EXECUTADO: {
    value: 4, icon: 'fa-check-circle', title: 'Executado', color: 'success',
  },
  ERRO: {
    value: 5, icon: 'fa-circle-exclamation', title: 'Erro', color: 'red',
  },
  CANCELADO: {
    value: 6, icon: 'fa-circle-xmark', title: 'Cancelado', color: 'warning',
  },
};

const getStatus = ({ value }, item) => statusProps[item[value]];
const setToCancel = ({ value }, item) => ({
  value: item[value],
  buttons: [{
    color: 'warning',
    icon: 'cancel',
    title: 'Cancelar',
    name: 'cancelar',

    onClick: (item) => {

      app.__vue__.$store.dispatch('dialog/show', {
        title: `${item.idversaoagendamento} - ${item.nomecliente}, ${item.data.split('-').reverse().join('/')} ${item.hora}`,
        text: 'Deseja cancelar o agendamento?',
        showTextField: true,
        requiredTextField: true,
        textFieldTitle: 'Motivo do cancelamento',
        buttons: [{
          color: 'success',
          icon: 'check',
          title: 'Sim',
          name: 'btn-sim',

          onClick: (props) => {
            try {
              const { motivo } = props;

              if (motivo.length < 30) {
                return;
              }

              agendamento.cancelar(item.idversaoagendamento, motivo);
              item.value = 0;
              item.cancelar = false;
              item.status = 'CANCELADO';
              item.buttons = [];
              app.__vue__.$store.dispatch('alert/success', 'Agendamento cancelado com sucesso');
            } catch (e) {
              console.error(e);
              app.__vue__.$store.dispatch('alert/error', 'Erro ao cancelar agendamento');
            } finally {
              props.motivo = '';
              app.__vue__.$store.dispatch('dialog/hide');
            }
          },
        }, {
          color: 'error',
          icon: 'close',
          title: 'Não',
          name: 'btn-nao',
          onClick: () => app.__vue__.$store.dispatch('dialog/hide'),
        }],
      });
    },
  }],
});

const serverStatus = {
  '-15': { color: 'success', icon: 'fa-circle-check', title: 'Ativo' },
  '+15': { color: 'warning', icon: 'fa-circle-exclamation', title: 'Atenção' },
  '+30': { color: 'error', icon: 'fa-circle-xmark', title: 'Inativo' },
};

const getStatusAtividade = ({ value }, item) => ({
  value: item[value],
  ...serverStatus[item.tempoatividade],
});

const moduleProps = {
  codigodb: { text: 'Empresa' },
  utilizacslalur: { text: 'Lalur' },
  utilizacscomercializacao: { text: 'Comercialização' },
  utilizacscontabilidade: { text: 'Contabilidade' },
  utilizacsemissor: { text: 'e-Doc' },
  utilizacsescritafiscal: { text: 'Escrita fiscal' },
  utilizacsfaturamento: { text: 'Faturamento' },
  utilizacsfinanceiro: { text: 'Financeiro' },
  utilizacslcdpr: { text: 'LCDPR' },
  utilizacsromaneio: { text: 'Romaneio' },
  utilizacstributacao: { text: 'Tributação' },
  utilizamodulopesador: { text: 'Pesador' },
  utilizamodulolibsupervisor: { text: 'Lib. Supervisor' },
  utilizamodulocsweb: { text: 'CS Web' },
};

const getIconCheck = ({ value }, item) => {
  if (!item[value] || item[value] == 'N') {
    return {
      value: item[value], color: 'error', icon: 'fa-circle-xmark', title: '',
    };
  }

  return {
    value: item[value], color: 'success', icon: 'fa-circle-check', title: '',
  };
};

const getModuleField = (field) => ({
  fn: getIconCheck,
  name: field,
  ...moduleProps[field],
  icon: 1,
  sort: 1,
});

export default {
  clientes: [
    fields({ name: 'idcliente', text: 'ID', width: 70, sort: 1 }),
    fields({
      name: 'nome',
      text: 'Cliente',
      link: 1,
      sort: 1,
      fn: getLink(({ hash }) => `/clientes/detalhes/${hash}`),
    }),
    fields({ name: 'tipoempresa', text: 'Caracterização', width: 250, sort: 1 }),
    fields({ name: 'uf', text: 'UF', width: 100, sort: 1 }),
    fields({ name: 'ip', text: 'IP', width: 100 }),
    fields({ name: 'versao', text: 'Versão', width: 120, sort: 1 }),
    fields({ name: 'statusbase', text: 'Status', width: 100, sort: 1 }),
  ],
  empresas: [
    fields({ name: 'codigodb', text: 'ID', sort: 1 }),
    fields({ name: 'razao', text: 'Razão Social', sort: 1 }),
    fields({ name: 'fantasia', text: 'Fantasia', sort: 1 }),
    fields({ name: 'cnpj', text: 'CNPJ/CPF', sort: 1 }),
    fields({ name: 'tipoempresa', text: 'Caracterização', sort: 1 }),
    fields({ name: 'cidade', text: 'Cidade', sort: 1 }),
    fields({ name: 'sigla', text: 'UF', sort: 1 }),
  ],
  modulos: [
    fields({ ...getModuleField('codigodb'), icon: 0, fn: undefined }),
    fields(getModuleField('utilizacscomercializacao')),
    fields(getModuleField('utilizacsromaneio')),
    fields(getModuleField('utilizacsfinanceiro')),
    fields(getModuleField('utilizacscontabilidade')),
    fields(getModuleField('utilizacsescritafiscal')),
    fields(getModuleField('utilizacstributacao')),
    fields(getModuleField('utilizacslalur')),
    fields(getModuleField('utilizacslcdpr')),
    fields(getModuleField('utilizacsemissor')),
    fields(getModuleField('utilizamodulopesador')),
    fields(getModuleField('utilizamodulolibsupervisor')),
    fields(getModuleField('utilizamodulocsweb')),
  ],
  erros: [
    fields({
      name: 'data', text: 'Data', fn: getDate, sort: 1,
    }),
    fields({ name: 'erro', text: 'Erro' }),
    fields({ name: 'formulario', text: 'Formulário' }),
    fields({ name: 'componente', text: 'Componente' }),
    fields({ name: 'ip', text: 'IP' }),
    fields({ name: 'host', text: 'Host' }),
    fields({
      name: 'versaodesc',
      text: 'Versão',
      link: 1,
      fn: getLink(({ versao }) => `/versoes/detalhes/${versao}`),
    }),
  ],
  errosDetalhes: [
    fields({ name: 'iderro', text: 'ID', sort: 1 }),
    fields({ name: 'data', text: 'Data', fn: getDate, sort: 1 }),
    fields({ name: 'formulario', text: 'Formulário', sort: 1 }),
    fields({ name: 'componente', text: 'Componente', sort: 1 }),
    fields({ name: 'ip', text: 'IP', extra: 1 }),
    fields({ name: 'host', text: 'Host', extra: 1 }),
    fields({ name: 'sistema', text: 'Módulo', fn: getModulo, sort: 1 }),
    fields({
      name: 'nome',
      text: 'Cliente',
      link: 1,
      sort: 1,
      fn: getSubLink(({ clienteobj: { hash } }) => `/clientes/detalhes/${hash}`, 'clienteobj'),
    }),
    fields({ name: 'empresa', text: 'Cód. Empresa' }),
    fields({ name: 'so', text: 'Sistema', fn: getSistema, extra: 1 }),
    fields({ name: 'usuario', text: 'Usuário', extra: 1 }),
    fields({
      name: 'versaodesc',
      text: 'Versão',
      link: 1,
      sort: 1,
      fn: getLink(({ versao, versaodesc }) => `/versoes/detalhes/${versao}?versaodesc=${versaodesc}`),
    }),
  ],
  errosAgrupados: [
    fields({
      name: 'erro',
      text: 'Erro',
      sort: 1,
      link: 1,
      fn: getLink(({ hash }) => `/erros/detalhes/${hash}`),
    }),
    fields({ name: 'tipo', text: 'Tipo', sort: 1 }),
    fields({ name: 'formulario', text: 'Formulário', sort: 1 }),
    fields({
      name: 'ocorrencias', text: 'Ocorrências', width: 150, sort: 1,
    }),
    fields({
      name: 'clientes', text: 'Clientes', width: 150, sort: 1,
    }),
    fields({
      name: 'versaoinicial',
      text: 'Inicial',
      link: 1,
      sort: 1,
      fn: getLink(({ vinicial }) => `/versoes/detalhes/${vinicial}`),
    }),
    fields({
      name: 'versaofinal',
      text: 'Última',
      link: 1,
      sort: 1,
      fn: getLink(({ vfinal }) => `/versoes/detalhes/${vfinal}`),
    }),
  ],
  errosAgrupadosVersao: [
    fields({
      name: 'erro',
      text: 'Erro',
      sort: 1,
      link: 1,
      fn: getLink(({ hash }) => `/erros/detalhes/${hash}`),
    }),
    fields({ name: 'formulario', text: 'Formulário', sort: 1 }),
    fields({ name: 'ocorrencias', text: 'Ocorrências', sort: 1 }),
    fields({ name: 'ocorrenciasversao', text: 'na Versão', sort: 1 }),
    fields({ name: 'clientes', text: 'Clientes', sort: 1 }),
    fields({
      name: 'versaoinicial',
      text: 'Inicial',
      link: 1,
      sort: 1,
      fn: getLink(({ vinicial }) => `/versoes/detalhes/${vinicial}`),
    }),
    fields({
      name: 'versaofinal',
      text: 'Última',
      link: 1,
      sort: 1,
      fn: getLink(({ vfinal }) => `/versoes/detalhes/${vfinal}`),
    }),
  ],
  versoes: [
    fields({
      name: 'versao',
      text: 'Versão',
      link: 1,
      fn: getLink(({ idversao, versao }) => `/versoes/detalhes/${idversao}?versaodesc=${versao}`),
    }),
    fields({ name: 'clientes', text: 'Clientes' }),
    fields({ name: 'erros', text: 'Erros' }),
  ],
  versoesCliente: [
    fields({ name: 'idversao', text: 'ID' }),
    fields({
      name: 'versao',
      text: 'Versão',
      link: 1,
      fn: getLink(({ idversao, versao }) => `/versoes/detalhes/${idversao}?versaodesc=${versao}`),
    }),
    fields({
      name: 'data', text: 'Atualização', fn: getSubDate('versaocliente'), sort: 0,
    }),
  ],
  versoesClientes: [
    fields({ name: 'idcliente', text: 'ID' }),
    fields({
      name: 'nome',
      text: 'Cliente',
      link: 1,
      sort: 1,
      fn: getLink(({ hash }) => `/clientes/detalhes/${hash}`),
    }),
    fields({
      name: 'data', text: 'Atualização', fn: getDate, sort: 0,
    }),
    fields({ name: 'nestaversao', text: 'Nesta Versão', sort: 1 }),
  ],
  versoesErros: [
    fields({
      name: 'data', text: 'Data', fn: getDate, sort: 1,
    }),
    fields({ name: 'erro', text: 'Erro' }),
    fields({ name: 'formulario', text: 'Formulário' }),
  ],
  versoesExecutaveis: [
    // fields({name: 'idversaoarquivo', text: 'Id'}),
    fields({ name: 'name', text: 'Nome', sort: 1 }),
    fields({ name: 'url', text: 'URL' }),
    fields({ name: 'hash', text: 'HASH' }),
  ],
  versoesScripts: [
    // fields({name: 'idversaoscript', text: 'Id'}),
    fields({ name: 'idstudio', text: 'Id', sort: 1 }),
    fields({ name: 'script', text: 'Script' }),
  ],
  usuarios: [
    fields({ name: 'codigodb', text: 'Código', sort: 1 }),
    fields({ name: 'login', text: 'Login', sort: 1 }),
    fields({ name: 'nome', text: 'Nome', sort: 1 }),
    fields({ name: 'ativo', text: 'Ativo', fn: getBool }),
  ],
  versaoAgendamento: [
    fields({ name: 'idversaoagendamento', text: 'ID', sort: 1 }),
    fields({name: 'nomecliente', text: 'Cliente', link: 1, sort: 1, fn: getLink(({ hash }) => `/clientes/detalhes/${hash}`)}),
    fields({
      name: 'versaoatual', 
      text: 'Versão Atual', 
      link: 1, 
      sort: 1, 
      fn: getLink(({ idversaoatual, versaoatual }) => `/versoes/detalhes/${idversaoatual}?versaodesc=${versaoatual}`),
    }),
    fields({ name: 'data', text: 'Data', fn: getDateOnly, sort: 1}),
    fields({ name: 'hora', text: 'Hora', sort: 1 }),
    fields({
      name: 'versao', 
      text: 'Versão', 
      link: 1, sort: 1, 
      fn: getLink(({ idversao, versao }) => `/versoes/detalhes/${idversao}?versaodesc=${versao}`),
    }),
    fields({ name: 'status', text: 'Status', fn: getStatus, sort: 1, icon: 1}),
    
    fields({ name: 'timeline', text: 'Timeline', sort: 1 }),
    fields({ name: 'cancelar', text: 'Cancelar', buttons: 1, fn: setToCancel, sort: 0 }),
    fields({
      name: 'log',
      text: 'Logs',
      buttons: 1,
      fn: getBtnLink(() => [{
        color: 'info', icon: 'fa-info-circle', text: 'Detalhes', internalEvent: true, eventName: 'showLog',
      }]),
    }),
    fields({ name: 'usuario', text: 'Usuário', sort: 1 }),
    fields({ name: 'statusserver', text: 'Status CS Server', sort: 1, align: 'center', icon: 1}),
  ],
};
