import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import '@/components';
import '@/plugins';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-toastification/dist/index.css';
import 'vue-pivottable/dist/vue-pivottable.css';

import App from '@/App';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';
import Toast from 'vue-toastification';
import vuetify from './plugins/vuetify';

sync(store, router);

Vue.use(VueApexCharts);

Vue.use(Toast, {
  timeout: 3500,
  pauseOnHover: true,
  newestOnTop: true,
});

Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
