<template lang="pug">
div
  v-row.no-gutters.align-center.wrap.justify-end
    v-col(cols="12", md="2")
      v-text-field.mr-1(
        v-model="filters.datainicial",
        color="primary",
        label="Data/Hora inicial",
        type="datetime-local",
        hide-details,
        dense,
        outlined
      )
    v-col(cols="12", md="2")
      v-text-field.mr-1(
        v-model="filters.datafinal",
        color="primary",
        label="Data/Hora final",
        type="datetime-local",
        hide-details,
        dense,
        outlined
      )
    v-btn.mx-1(
      :disabled="loading",
      :loading="loading",
      outlined,
      icon,
      color="primary",
      title="Atualizar",
      @click="getLogs"
    )
      v-icon(small) fa-sync
    v-btn.mx-1(
      outlined,
      icon,
      color="primary",
      title="Dashboard",
      @click="() => { tipoVisualizacao = 'Dashboard'; getLogs(); }"
    )
      v-icon(small) fa-chart-line
    v-btn.ml-1.mr-2(
      outlined,
      icon,
      color="primary",
      title="Grid",
      @click="tipoVisualizacao = 'Dados'"
    )
      v-icon(small) fa-table
  v-divider
  div(v-if="tipoVisualizacao === 'Dashboard'")
    v-card
      v-row.pa-0.pt-0.mt-0(no-gutters)
        v-col.mt-0(cols="12", xs="12", sm="12", md="5")
          Chart(
            chartType="pie",
            height="90%",
            width="90%",
            title="Total de requisições por status",
            :labels="dashStatus.labels",
            :dataset="dashStatus.values"
          )
        v-col.mt-0(cols="12", xs="12", sm="12", md="5")
          Chart(
            chartType="pie",
            height="90%",
            width="90%",
            title="Total de requisições por tipo",
            :labels="dashType.labels",
            :dataset="dashType.values"
          )
        v-col.mt-0(cols="12", xs="12", sm="12", md="2")
          v-card.mx-2.mb-2
            v-card-title.mx-2
              span.title Total de requisições
            v-card-text.mx-2
              span.title {{ dashTotalRequests }}
          v-card.mx-2.mb-2
            v-card-title.mx-2
              span.title Tempo médio de resposta
            v-card-text.mx-2
              span.title {{ dashResponseTime }} ms

  DataFilter(
    v-if="tipoVisualizacao === 'Dados'",
    :items="logs",
    :filterProps="filterProps"
  )
    template(v-slot:default="{ items }")
      v-data-table(
        :headers="headers",
        :items="items",
        :loading="loading",
        :items-per-page="items.length",
        dense,
        hide-default-footer
      )
        template(v-slot:item.data_hora="{ item }")
          span {{ formatDate(item.data_hora) }}
        template(v-slot:item.metodo="{ item }")
          v-chip(small, label, outlined) {{ item.metodo }}
        template(v-slot:item.duracao="{ item }")
          span {{ item.duracao }} ms
        template(v-slot:item.status="{ item }")
          v-chip(
            :color="getColorByStatus(item.status)",
            :text-color="getColorByStatus(item.status)",
            small,
            label,
            outlined
          ) {{ item.status }}
</template>

<script>
import api from '@/api/csapi';

export default {
  name: 'ApiLogs',
  data: () => ({
    tipoVisualizacao: 'Dashboard',
    loading: false,
    headers: [
      { text: 'Data', value: 'data_hora' },
      { text: 'IP', value: 'ip' },
      { text: 'User Agent', value: 'user_agent' },
      { text: 'Método', value: 'metodo' },
      { text: 'Rota', value: 'rota' },
      { text: 'Status', value: 'status' },
      { text: 'Duração', value: 'duracao' },
      { text: 'Mensagem', value: 'message' },
    ],
    filterProps: {},
    filters: {
      datainicial: `${new Date().toISOString().split('T')[0]} 00:00`,
      datafinal: `${new Date().toISOString().split('T')[0]} 23:59`,
      type: null,
      ip: null,
      status: null,
    },
    logs: [],
    dashStatus: {
      labels: [],
      values: [],
    },
    dashType: {
      labels: [],
      values: [],
    },
    dashResponseTime: 0,
    dashTotalRequests: 0,
    dashResponseTimeByDate: {
      labels: [],
      values: [],
    },
  }),
  computed: {
    //
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    getColorByStatus(status) {
      if (status >= 200 && status < 300) {
        return 'success';
      } if (status >= 300 && status < 400) {
        return 'warning';
      } if (status >= 400 && status < 500) {
        return 'error';
      } if (status >= 500) {
        return 'error darken-2';
      }
    },

    mountDashboards() {
      this.dashStatus.labels = this.logs
        .map((item) => item.status)
        .sort()
        .filter((item, index, self) => self.indexOf(item) === index);

      this.dashStatus.values = this.dashStatus.labels.map((item) => this.logs.filter((log) => log.status === item).length);

      this.dashType.labels = this.logs
        .map((item) => item.metodo)
        .sort()
        .filter((item, index, self) => self.indexOf(item) === index);

      this.dashType.values = this.dashType.labels.map((item) => this.logs.filter((log) => log.metodo === item).length);

      this.dashResponseTime = Math.round(
        this.logs.reduce((acc, item) => acc + item.duracao, 0)
          / this.logs.length,
      );

      this.dashTotalRequests = this.logs.length;
    },

    mountDataFilters() {
      const statusses = this.logs
        .map((item) => item.status)
        .sort()
        .map((item) => ({
          field: 'status',
          text: item,
          value: item,
        }));

      this.filterProps = {
        includeSearch: true,
        items: [
          {
            text: 'IP',
            type: 'select',
            values: this.logs
              .map((item) => item.ip)
              .sort()
              .map((item) => ({
                field: 'ip',
                text: item,
                value: item,
              })),
          },
          {
            text: 'User Agent',
            type: 'select',
            values: this.logs
              .map((item) => item.user_agent)
              .sort()
              .map((item) => ({
                field: 'user_agent',
                text: item,
                value: item,
              })),
          },
          {
            text: 'Método',
            type: 'select',
            values: this.logs
              .map((item) => item.metodo)
              .sort()
              .map((item) => ({
                field: 'metodo',
                text: item,
                value: item,
              })),
          },
          {
            text: 'Status',
            type: 'select',
            values: statusses,
          },
        ],
      };
    },

    async getLogs() {
      this.loading = true;
      try {
        const res = await api.post('/__internals__/logs/api', this.filters);
        this.logs = res.data;

        this.mountDashboards();
        this.mountDataFilters();

        this.logs = this.logs.sort(
          (a, b) => new Date(b.data_hora) - new Date(a.data_hora),
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getLogs();
  },
};
</script>
