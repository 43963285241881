export default {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, { user, token, token2 }) {
    state.status = { loggedIn: true };
    state.auth = token;
    state.authHom = token2;
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.auth = null;
    state.authHom = null;
    state.user = null;
  },
  setCSAPI(state, csapi) {
    state.csapi = csapi;
  },
};
