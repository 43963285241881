<template lang="pug">
div
  v-card.mx-2.mb-2
    v-row.mx-2(no-gutters, align-center, justify-center)
      v-col.px-1(
        cols="12",
        xs="12",
        sm="12",
        md="4",
        align-center,
        justify-center
      )
        v-card.my-2.pa-0.pt-0.white--text(
          outlined,
          :color="connected ? 'success' : 'error'"
        )
          v-row.mx-2(no-gutters)
            v-icon.mr-1(color="white") mdi-server-security
            span.title {{ serverUrl }}

      v-col.px-1(
        cols="12",
        xs="12",
        sm="12",
        md="4",
        align-center,
        justify-center
      )
        v-card.my-2.pa-0.pt-0.white--text(outlined, color="primary")
          v-row.mx-2(no-gutters)
            v-icon.mr-1(color="white") mdi-connection
            span.title Conexões ativas
            v-spacer
            span.title {{ connectionsCount }}
      v-col.px-1(
        cols="12",
        xs="12",
        sm="12",
        md="4",
        align-center,
        justify-center
      )
        v-card.my-2.pa-0.pt-0.white--text(outlined, color="primary")
          v-row.mx-2(no-gutters)
            v-icon.mr-1(color="white") fa-duotone fa-user-secret
            span.title Administradores
            v-spacer
            span.title {{ adminsCount }}
    v-row.mx-2(no-gutters, align-center, justify-center)
      v-col.px-1(
        cols="12",
        xs="12",
        sm="12",
        md="4",
        align-center,
        justify-center
      )
        v-card.my-2.pa-0.pt-0.white--text(outlined, color="primary")
          v-row.mx-2(no-gutters, justify-space-between)
            v-icon.mr-1(color="white") mdi-application-parentheses
            span.title Server/Apps
            v-spacer
            span.title {{ clientsCount }}

      v-col.px-1(
        cols="12",
        xs="12",
        sm="12",
        md="4",
        align-center,
        justify-center
      )
        v-card.my-2.pa-0.pt-0.white--text(outlined, color="primary")
          v-row.mx-2(no-gutters, justify-space-between)
            v-icon.mr-1(color="white") fa-duotone fa-server
            span.title Servidores
            v-spacer
            span.title {{ serverConnections.length }}
      v-col.px-1(
        cols="12",
        xs="12",
        sm="12",
        md="4",
        align-center,
        justify-center
      )
        v-card.my-2.pa-0.pt-0.white--text(outlined, color="primary")
          v-row.mx-2(no-gutters, justify-space-between)
            v-icon.mr-1(color="white") fa-duotone fa-user
            span.title Aplicativos
            v-spacer
            span.title {{ supervisorConnections.length }}
      v-spacer
    v-divider
    v-row.mx-2(no-gutters, align-center, justify-center)
      v-expansion-panels.mb-2(focusable, v-model="expanded", hover)
        v-expansion-panel#panel-servers
          v-expansion-panel-header
            span.title Servidores
          v-expansion-panel-content
            v-col(
              align-center,
              justify-center
            )
              DataFilter(
                classes="pa-0 ma-0 elevation-0",
                :items="serverConnections"
              )
                template(v-slot:default="{ items }")
                  v-data-table(
                    :headers="serverHeaders",
                    :items="items",
                    dense,
                    item-key="socket_id",
                    hide-default-footer,
                    :items-per-page="items.length"
                  )
                    template(v-slot:item.nome="{ item }")
                      span {{ item.nome }}
                    template(v-slot:item.socket_id="{ item }")
                      v-chip.ma-1(label, outlined)
                        pre {{ item.socket_id }}

                    template(v-if="showCommands" v-slot:item.actions="{ item }")
                      v-menu
                        template(v-slot:activator="{ on, attrs }")
                          v-icon.small(
                            v-bind="attrs",
                            v-on="on",
                            color="primary",
                            @click="showSheet(item)"
                          ) mdi-menu

        v-expansion-panel
          v-expansion-panel-header
            span.title Aplicativos
          v-expansion-panel-content
            v-col(
              align-center,
              justify-center
            )
              DataFilter(
                classes="pa-0 ma-0 elevation-0",
                :items="supervisorConnections"
              )
                template(v-slot:default="{ items }")
                  v-data-table(
                    :headers="supervisorHeaders",
                    :items="items",
                    dense,
                    :expanded.sync="clientsExpanded",
                    item-key="socket_id",
                    :single-expand="true",
                    show-expand,
                    hide-default-footer
                  )
                    template(v-slot:item.socket_id="{ item }")
                      v-chip.ma-1(label, outlined)
                        pre {{ item.socket_id }}

                    template(v-slot:expanded-item="{ headers, item }")
                      pre {{ item.token }}

  v-bottom-sheet(v-if="sheetItem", v-model="sheet", inset)
    v-sheet(height="300px", rounded, elevation="24")
      v-row.mx-2(no-gutters, align-center)
        span.title {{ sheetItem.nome }}
        v-spacer
        v-icon(color="error", @click="hideSheet()") fa-close

      v-divider
      v-row.mx-2.mt-2(no-gutters, align-center)
        v-col(xs="12", sm="8", md="4", lg="3", xl="4")
          v-select.mt-2(
            outlined,
            dense,
            item-value="type",
            item-text="text",
            label="Tipo",
            v-model="eventDisconectType",
            :items="[ { type: 'client', text: 'App desconectar do Servidor' }, { type: 'server', text: 'Servidor desconectar app' }, ]"
          )
        v-col(
          v-if="eventDisconectType === 'client'",
          xs="12",
          sm="4",
          md="3",
          lg="2",
          xl="1"
        )
          v-text-field.ml-1.mt-2(
            v-model="eventDisconectInterval",
            outlined,
            dense,
            label="Intervalo offline (ms)"
          )
        v-btn.ml-1(
          color="error",
          :disabled="eventDisconectType === null",
          @click="disconnectApp"
        ) Desconectar
      v-divider
      v-row.mx-2.mt-2(no-gutters, align-center)
        v-col(xs="12", sm="8", md="4", lg="3", xl="3")
          v-text-field.ml-1.mt-2(
            v-model="eventEmitTo",
            outlined,
            dense,
            label="Enviar para (all para todos)"
          )
          v-text-field.ml-1(
            v-model="eventEmitName",
            outlined,
            dense,
            label="Nome do evento"
          )
        v-textarea.ml-1.mt-2(
          v-model="eventEmitData",
          outlined,
          dense,
          label="Dados do evento",
          rows="4"
        )
        v-btn.ml-1(
          color="primary",
          :disabled="eventEmitName === null",
          @click="emitEvent"
        ) Emitir
</template>

<script>
import { state } from '@/socket';
import api from '@/api/csapi';

export default {
  data() {
    return {
      showCommands: process.env.NODE_ENV !== 'production',
      expanded: 0,
      clientsExpanded: [],
      sheet: false,
      sheetItem: null,
      serverUrl: process.env.VUE_APP_CSAPI_SOCKET_URL,
      serverHeaders: [
        { text: 'Socket ID', value: 'socket_id' },
        { text: 'Conectado em', value: 'connected_at' },
        { text: 'Cliente', value: 'nome' },
        { text: 'Hash', value: 'hash' },
        { text: 'Serial HD', value: 'serial_hd' },
        { text: '', value: 'actions', sortable: false, align: 'center' },
      ],
      serverConnections: [],
      supervisorHeaders: [
        { text: 'Socket ID', value: 'socket_id' },
        { text: 'Conectado em', value: 'connected_at' },
        { text: 'Nome', value: 'nome' },
        { text: 'Email', value: 'email' },
        { text: '', value: 'data-table-expand' },
      ],
      supervisorConnections: [],
      eventDisconectType: null,
      eventDisconectInterval: 0,
      eventEmitTo: 'null',
      eventEmitName: 'message',
      eventEmitData: '{ "message": "" }',
    };
  },

  watch: {
    socketConnections() {},
  },

  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    showSheet(item) {
      this.sheetItem = item;
      this.eventEmitTo = item.socket_id;
      this.sheet = true;
    },
    hideSheet() {
      this.sheetItem = null;
      this.sheet = false;
      this.eventDisconectType = null;
    },
    async disconnectApp() {
      const res = await api.get(
        `/v1/socket/${this.eventDisconectType}/disconnect/${this.sheetItem.socket_id}/${this.eventDisconectInterval}`,
      );
      this.$toast(res.data.message);
      this.hideSheet();
    },
    async emitEvent() {
      console.log(this.eventEmitTo, this.eventEmitName, this.eventEmitData);
      const res = await api.get(`/v1/socket/generic/${this.eventEmitTo}/${this.eventEmitName}/${this.eventEmitData}`);
      // console.log(res.data);
      this.$toast(res.data.message);
    },
  },

  computed: {
    connected() {
      return state.connected;
    },
    connectionsCount() {
      return state.serverStatus
        ? state.serverStatus.clients_count + state.serverStatus.admins_count
        : 0;
    },
    clientsCount() {
      return state.serverStatus ? state.serverStatus.clients_count : 0;
    },
    adminsCount() {
      return state.serverStatus ? state.serverStatus.admins_count : 0;
    },
    socketConnections() {
      this.supervisorConnections = [];
      this.serverConnections = [];

      state.socketConnections.forEach((connection) => {
        const { data } = connection;
        if (data.token.hash) {
          console.log(data);
          this.serverConnections.push({
            socket_id: connection.id,
            connected_at: this.formatDate(data.connectedAt),
            hash: data.token.hash,
            id: data.token.id,
            nome: data.token.nome,
            serial_hd: data.token.serialhd,
          });
        } else {
          this.supervisorConnections.push({
            socket_id: connection.id,
            connected_at: this.formatDate(data.connectedAt),
            email: data.token.email,
            nome: data.token.nome,
            empresas: data.token.empresas,
            token: data.token,
          });
        }
      });

      return state.socketConnections;
    },
  },
};
</script>
